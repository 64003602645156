<template>
  <div class="preview-notification mb-4">
    <div class="preview-notification-before">
      <img src="/img/logo_clnb_ro_mini_blue.svg" alt="">
    </div>
    <div class="preview-notification-body">
      <div class="preview-notification-title">{{ item.title }}</div>
      <div class="preview-notification-text">
        {{ item.text && item.text.length > 100 ? item.text.slice(0, 100) + '...' : item.text }}
      </div>
    </div>
    <div class="preview-notification-after ml-auto">
      <span class="preview-notification-after-time">Сейчас</span>
<!--      <img v-if="item.img" :src="item.img" alt="">-->
    </div>
  </div>
</template>

<script>
export default {
  name: "PushNotificationPreview",
  props: ['item']
}
</script>

<style scoped lang="scss">
.preview-notification {
  position: absolute;
  right: 20px;
  border-radius: 18px;
  background: #f5f5f5;
  padding: 10px 14px 10px 10px;
  display: flex;
  align-items: flex-start;
  width: 370px;
  &-before {
    min-width: 38px;
    height: 38px;
    border-radius: 9px;
    margin: auto 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-body {
    padding: 0 10px;
  }

  &-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
  }

  &-text {
    font-size: 15px;
    letter-spacing: -0.2px;
    line-height: 143%;
    display: -webkit-box;
    max-width: 256px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-after {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-time {
      display: block;
      margin-bottom: 4px;
      color: #838383;
      font-size: 13px;
    }

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 6px;
    }
  }
}

</style>