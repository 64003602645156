<template>
  <div class="popup-app-preview" :class="{'small': small}">
    <div class="popup-container">
      <img v-if="item.img" :src="item.img" alt="">
      <div class="popup-title">{{ item.title || 'Заголовок' }}</div>
      <div class="popup-text">{{ item.text || 'Текст push-уведомления' }}</div>
      <div class="popup-button" v-if="item.button_text">{{ item.button_text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppPopupNotificationPreview",
  props: ['item', 'small']
}
</script>

<style scoped lang="scss">
.popup-app-preview {
  margin-top: 120px;
  background: url("/img/client_app_mainscreen.png") no-repeat center center;
  background-size: contain;
  width: 270px;
  min-height: 500px;
  position: relative;
  .popup-container {
    position: absolute;
    bottom: 20px;
    left: 30px;
    background: #FFFFFF;
    width: 210px;
    border-radius: 16px;
    text-align: center;
    padding: 2px;

    img {
      margin-bottom: 20px;
      height: 110px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .popup-title {
      padding-top: 10px;
      margin: 0 0 10px;
      font-size: 14px;
    }

    .popup-text {
      margin-bottom: 20px;
      font-size: 9px;
    }

    .popup-button {
      margin-bottom: 10px;
      height: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 48px;
      background: #9BF0F5;
      font-size: 10px;
    }
  }
  &.small {
    min-height: 420px;
    .popup-container {
      bottom: 14px;
      left: 46px;
      width: 177px;
    }
  }

}

</style>