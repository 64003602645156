export default {
  methods: {
    isLocalhost() {
      return location.host.indexOf('localhost') > -1;
    },
    clone(obj) {
      if (obj === null || typeof obj !== "object") return obj;
      let copy = Array.isArray(obj) ? [] : {};
      for (let i in obj) copy[i] = this.clone(obj[i]);
      return copy;
    },
    setCookie(name, value, days) {
      var expires = "";
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
  }
}