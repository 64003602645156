<template>
  <v-row v-if="data" class="dashboard_cards">
    <v-col cols="3" class="mobile-col-basis">
      <v-card>
        <v-card-title>
          <h4>Источники</h4>
        </v-card-title>
        <v-divider></v-divider>
        <div v-if="!loading" style="padding: 0 10px">
          <Doughnut
              ref="doughnut_chart"
              :chart-options="chartOptions"
              :chart-data="doughnutChartData"
              chart-id="doughnut-bar"
              :width="300"
          />
        </div>
        <v-skeleton-loader v-if="loading" type="list-item@3"/>
        <v-list v-else dense style="min-height: 250px;padding-top: 0">
          <v-list-item v-for="(item, source, index) in data.orders.sources" @click="selectSource(source)"
                       v-if="item.current && +item.current.sum">
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content style="text-transform: capitalize;min-width: 100px"
                                     :style="`color: ${doughnutChartData.datasets ? doughnutChartData.datasets[0].backgroundColor[index] : ''}`"
                >{{ source }}:
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <div style="text-align: right;">
                    <OrdersSum :sum="item.current ? item.current.sum : '---'"
                               :count="item.current ? item.current.count : 0"></OrdersSum>
                    <v-tooltip top v-if="item.current">
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" style="color: #3399ff;font-size: 10px">
                          {{ $root.printCost(item.current.sum_new) }} ({{ item.current.count_new || 0 }} шт) /
                          {{ $root.printCost(item.current.sum_not_new) }} ({{ item.current.count_not_new || 0 }} шт)
                        </div>
                      </template>
                      <span>Новые клиенты / повторные</span>
                    </v-tooltip>
                  </div>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="border-top: 1px solid #eee">
              <v-col>Итого</v-col>
              <v-col cols="auto" style="text-align: right;">
                <OrdersSum :sum="sources_values.reduce((prev, cur) => prev + (cur.current ? cur.current.sum : 0), 0)"
                           :count="sources_values.reduce((prev, cur) => prev + (cur.current ? cur.current.count : 0), 0)"></OrdersSum>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" style="color: #3399ff;font-size: 10px">
                      {{
                        $root.printCost(sources_values.reduce((prev, cur) => prev + (cur.current ? cur.current.sum_new || 0 : 0), 0))
                      }}
                      ({{
                        sources_values.reduce((prev, cur) => prev + (cur.current ? cur.current.count_new || 0 : 0), 0)
                      }}
                      шт) /
                      {{
                        $root.printCost(sources_values.reduce((prev, cur) => prev + (cur.current ? cur.current.sum_not_new || 0 : 0), 0))
                      }}
                      ({{
                        sources_values.reduce((prev, cur) => prev + (cur.current ? cur.current.count_not_new || 0 : 0), 0)
                      }}
                      шт)
                    </div>
                  </template>
                  <span>Новые клиенты / повторные</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="3" class="mobile-col-basis">
      <v-card>
        <v-card-title>
          <h4>Оформление</h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-skeleton-loader v-if="loading" type="list-item@3"/>
        <v-list v-else dense style="min-height: 250px">
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Пропущенные звонки:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <v-tooltip v-if="data.calls.missed/(data.calls.missed+data.calls.received)>0.1" top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" style="color: red">{{ data.calls.missed }}</span>
                    </template>
                    <span>Норма - не более 10%</span>
                  </v-tooltip>
                  <template v-else>{{ data.calls.missed }}</template>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Принятые звонки:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.calls.received }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Ср. время ожидания ответа:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <v-tooltip v-if="data.calls.avg_answer_time>15" top>
                    <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on"
                                    style="color: red">{{ $root.printTimeDiff(data.calls.avg_answer_time, 1) }}</span>
                    </template>
                    <span>Норма - не более 15 секунд</span>
                  </v-tooltip>
                  <template v-else>{{ $root.printTimeDiff(data.calls.avg_answer_time, 1) }}</template>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Ср. время разговора:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printTimeDiff(data.calls.avg_call_duration, 1) }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Неотмененных:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <OrdersSum :sum="data.orders.current_sum" :count="data.orders.current"></OrdersSum>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Отмененных:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <div>
                    <OrdersSum :sum="data.orders.canceled_sum" :count="data.orders.canceled"></OrdersSum>
                    <InfoTip position="right" v-if="data.orders.canceled_orders.length"
                             :value="'Причины:<br>- '+data.orders.canceled_orders.join('<br> - ')"></InfoTip>
                  </div>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Перенесенных в другой город:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <div>
                    <OrdersSum :sum="data.orders.sent_to_sum" :count="data.orders.sent_to"/>
                  </div>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>До 1-го открытия заказа (сред):</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printTimeDiff(data.orders.oform_time.avg, 1) }}
                  <InfoTip v-if="data.orders.oform_time.managers"
                           :value="Object.keys(data.orders.oform_time.managers).map(mid=>`${$root.managerName(mid)}: ${$root.printTimeDiff(data.orders.oform_time.managers[mid].avg)} (${data.orders.oform_time.managers[mid].count} ${$root.numberWord(data.orders.oform_time.managers[mid].count, ['заказ', 'заказа', 'заказов'])})`).join('<br>')"></InfoTip>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="3" class="mobile-col-basis">
      <v-card>
        <v-card-title>
          <h4>Исполнение</h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-skeleton-loader v-if="loading" type="list-item@3"/>
        <v-list v-else dense style="min-height: 250px">
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Всего исполняется:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <OrdersSum :sum="data.orders.isp.sum" :count="data.orders.isp.count"></OrdersSum>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Клинеров с заказом (сред):</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.cleaners.withOrder.avg }}
                  <InfoTip value="Среднее количество клинеров с заказом в день (учитывая только дни, в которые есть заказы). Округляется до целого"/>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Выручка на 1 клинера:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <v-tooltip v-if="data.orders.isp.sum / data.cleaners.withOrder.avg / data.cleaners.withOrder.days<$store.state.user.revenuePerCleanerMin || data.orders.isp.sum / data.cleaners.withOrder.avg / data.cleaners.withOrder.days>$store.state.user.revenuePerCleanerMax" top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" style="color: red">{{
                          $root.printCost(data.orders.isp.sum / data.cleaners.withOrder.avg / data.cleaners.withOrder.days)
                        }}</span>
                    </template>
                    <span>Норма - между {{$store.state.user.revenuePerCleanerMin}} и {{$store.state.user.revenuePerCleanerMax}}</span>
                  </v-tooltip>
                  <template v-else>{{
                      $root.printCost(data.orders.isp.sum / data.cleaners.withOrder.avg / data.cleaners.withOrder.days)
                    }}</template>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Средняя оценка:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <v-tooltip v-if="data.orders.isp.rating_avg<4.7" top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" style="color: red">{{ data.orders.isp.rating_avg }}</span>
                    </template>
                    <span>Норма - не менее 4.7</span>
                  </v-tooltip>
                  <template v-else>{{ data.orders.isp.rating_avg }}</template>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item link @click="$eventBus.$emit('CleanerLateness', data.cleaners.lateness)">
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Опозданий:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <v-tooltip v-if="data.cleaners.lateness.length/data.orders.isp.count>0.1" top>
                    <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on" style="color: red">{{
                                  data.cleaners.lateness.length
                                }}</span>
                    </template>
                    <span>Норма - не более 10% от кол-ва заказов</span>
                  </v-tooltip>
                  <template v-else>{{ data.cleaners.lateness.length }}</template>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Новых клинеров:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.cleaners.new }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Оформленных клинеров:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.cleaners.oform }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Клинеров без фото:</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <v-tooltip v-if="data.cleaners.no_photo>1" top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" style="color: red">{{ data.cleaners.no_photo }}</span>
                    </template>
                    <span>Норма - не более 1</span>
                  </v-tooltip>
                  <template v-else>{{ data.cleaners.no_photo }}</template>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col cols="3" class="mobile-col-basis">
      <v-card>
        <v-card-title>
          <h4>Качество/возврат</h4>
        </v-card-title>
        <v-divider></v-divider>
        <v-skeleton-loader v-if="loading" type="list-item@3"/>
        <v-list v-else dense style="min-height: 250px">
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  <span>Новых постоянных клиентов (за год) <InfoTip value="Процент клиентов с 2 и более заказами из тех, кто появился в системе за последний год"/></span>
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.clients.regularPercent }}%
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item :key="type" v-for="(title, type) in $store.state.cleanTypes"
                       v-if="data.orders.by_type[type]">
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  <span>{{ title }}:</span>
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <OrdersSum :withAv="1" :sum="data.orders.by_type[type].sum"
                             :count="data.orders.by_type[type].count"></OrdersSum>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Створок помыто
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.orders.services.windowNum }} шт
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Количество негативных отзывов
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  <v-tooltip v-if="data.orders.isp.rating_low/data.orders.isp.count>0.1" top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" style="color: red">{{ data.orders.isp.rating_low }}</span>
                    </template>
                    <span>Норма - не более 10%</span>
                  </v-tooltip>
                  <template v-else>{{ data.orders.isp.rating_low }}</template>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  LTV и ср кол-во заказов (за год)
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printCost(data.clients.ltv.ltv) }}, {{ data.clients.ltv.orders * 1 || 0 }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  С приложением (всего)
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.clients.total_with_app }}, {{ data.clients.with_app_percent * 1 || 0 }}%
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Клиентов с 1+ заказом
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ data.clients.total_clients }} <span v-if="+data.clients.new_clients" style="color: #4caf50">+{{ data.clients.new_clients }}</span>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <template v-if="true">
        <v-card-title>
          <h4>Кешбэк</h4>
        </v-card-title>
        <v-divider></v-divider>
          <v-skeleton-loader v-if="loading" type="list-item@3"/>
          <v-list v-else dense>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>Заказов на сумму</v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{$root.printCost(data.orders.isp.sum)}}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Кешбэка начислено
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printCost(data.cashback.credited) }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Других бонусов начислено
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printCost(data.cashback.otherBonuses) }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Бонусов использовано
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printCost(data.cashback.used) }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Бонусов сгорело
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printCost(data.cashback.burned) }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Использовано кешбэка
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printCost(data.cashback.used*data.cashback.used/(data.cashback.credited+data.cashback.otherBonuses)) }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row style="justify-content: space-between!important;">
              <v-col>
                <v-list-item-content>
                  Использовано скидок
                </v-list-item-content>
              </v-col>
              <v-col cols="auto">
                <v-list-item-content class="align-end">
                  {{ $root.printCost(data.orders.discount) }}
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import OrdersSum from "./OrdersSum";
import {Doughnut, Line as LineChartGenerator} from "vue-chartjs/legacy"
import InfoTip from "./InfoTip";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement, CategoryScale, LinearScale, PointElement
} from 'chart.js'
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

ChartJS.register(ChartJSPluginDatalabels, Title, Tooltip, Legend, ArcElement, CategoryScale, LineElement, LinearScale, PointElement)

export default {
  name: 'DashboardStats',
  components: {InfoTip, OrdersSum, Doughnut, LineChartGenerator},
  props: ['cities', 'date'],
  data() {
    return {
      loading: false,
      debounceLoad: this.$root.debounce(() => {
        this.loading = true;
        this.$store.state.server.request('dashboard/get', {
          date: this.date,
          cities: this.cities.filter(c => +c)
        }, (data) => {
          this.loading = false;
          this.data = data.response;
        }, ()=>{
          this.loading = false;
        });
      }, 500),
      data: {
        orders: {oform_time: {}, canceled_orders: [], isp: {}, by_type: {}, sources: {}, services: {}},
        calls: {},
        cashback: {},
        cleaners: {lateness: [], withOrder: {}},
        clients: {ltv: {}}
      },
      chartOptions: {
        responsive: true,
        cutout: 50,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            // display: false
          },
          datalabels: {
            color: '#fff',
            textAlign: 'center',
            font: {
              lineHeight: 1.3,
              size: 12,
              weight: 600
            },
            formatter: (value, ctx) => {
              return value > 0 ? ctx.chart.data.labels[ctx.dataIndex] + '\n' + this.$root.printCost(value) : '';
            }
          }
        }
      },
    }
  },
  computed: {
    sources_values() {
      return Object.values(this.data.orders.sources);
    },
    doughnutChartData() {
      let dataSum = []
      for (let key in this.data.orders.sources) {
        if (this.data.orders.sources[key].current)
          dataSum.push(this.data.orders.sources[key].current ? this.data.orders.sources[key].current.sum : 0)
      }
      return {
        labels: this.data.orders.sources ? Object.keys(this.data.orders.sources).filter(item => this.data.orders.sources[item].current) : [],
        datasets: [
          {
            backgroundColor: ['#486746', '#3f6080', '#e32b8d', '#dd163a',
              '#6b6b6b', '#110107', '#810233', '#810270',
              '#3b0281', '#023d81', '#028163', '#4e8102',
              '#817602', '#6b0202', '#02186b'],
            data: dataSum
          }
        ]
      }
    },
  },
  methods: {
    selectSource(source) {
      let toggledSource = this.doughnutChartData.labels.indexOf(source)
      // this.doughnutChartData.datasets[0].data.splice(toggledSource, 1)
      // this.doughnutChartData.labels.splice(toggledSource, 1)
      this.$set(this.doughnutChartData, 'labels', [])
      this.$set(this.doughnutChartData, 'datasets[0].data', [])
      // this.doughnutChartData.labels = []
      // this.doughnutChartData.datasets[0].data = []
      this.$forceUpdate()
    },
    load() {
      this.debounceLoad();
    }
  },
  watch: {
    cities() {
      this.load();
    },
    date() {
      this.load();
    }
  },
  mounted() {
    this.load();
  }
}
</script>
<style>
.dashboard_cards .v-list-item__content {
  padding: 0 !important;
}
</style>
