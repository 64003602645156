<template>
  <v-dialog data-app v-model="opened" :retain-focus="false" max-width="900px" @click:outside="close">
    <v-container style="padding: 0; min-height: auto">
      <v-icon class="btn-close-modal" @click="close()" size="36">mdi-close</v-icon>
      <v-row>
        <v-col cols="6">
          <v-card style="margin-bottom: 0">
            <v-card-title>{{ item.id ? `Рассылка: ${item.title}` : 'Новая Push - рассылка' }}</v-card-title>
            <v-card-text>
              <v-row class="row-d-block">
                <v-subheader>Название</v-subheader>
                <v-text-field v-model="item.item_title" outlined placeholder="Например, Новый год 2024"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Заголовок</v-subheader>
                <v-text-field v-model="item.title" outlined placeholder="Например, Новый год"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Текст</v-subheader>
                <v-textarea v-model="item.text" placeholder="Текст рассылки" outlined/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Промокод</v-subheader>
                <v-text-field v-model="item.promo" outlined/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Изображение</v-subheader>
                <div @click="clickOnFileinput"
                     class="d-flex flex-column align-center justify-center pa-2 image-input tst"
                     style="background-size: cover;"
                     :style="`background-image: url('${item.img}');`">
                  <div class="d-flex flex-column pa-1 align-center text-center"
                       style="background: #FFFFFF; border-radius: 4px; opacity: .9; width: 100px; ">
                    <v-file-input
                        ref="file_input"
                        v-model="item.imageFile"
                        @change="setImage"
                        prepend-icon="mdi-paperclip"
                        outlined
                        hide-input
                        accept="image/*"
                        class="flex-grow-0"/>
                    Выбрать изображение
                  </div>
                </div>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Текст в кнопке уведомления</v-subheader>
                <v-text-field v-model="item.button_text" outlined placeholder="Например, Заказать уборку"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Ссылка на раздел в приложении</v-subheader>
                <v-select v-model="item.link" outlined placeholder="Выберите раздел приложения" :items="appRoutes"
                          item-text="name" item-value="path"/>
              </v-row>
            </v-card-text>
            <v-card-actions class="pb-4">
              <v-btn @click="create" :loading="loading_create">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <PushNotificationPreview :item="item"/>
          <AppPopupNotificationPreview :item="item"/>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import PushNotificationPreview from "@/views/clients/push/components/PushNotificationPreview";
import AppPopupNotificationPreview from "@/views/clients/push/components/AppPopupNotificationPreview";
import globalMixins from "@/mixins/globalMixins";

export default {
  name: "PushModal",
  components: {AppPopupNotificationPreview, PushNotificationPreview},
  emits: ['on-create'],
  mixins: [globalMixins],
  data() {
    return {
      opened: false,
      loading: false,
      loading_create: false,
      item: {img: ''},
      loading_promos: false,
      promos: [],
      appRoutes: [
        {path: '/order/create', name: 'Заказать уборку'},
        {path: '/address', name: 'Адрес'},
        {path: '/address/house_map', name: 'Карта дома'},
        {path: '/cleaners', name: 'Клинеры'},
        {path: '/user/settings', name: 'Настройки'},
        {path: '/user/bonuses/levels', name: 'Уровни'},
      ],
    }
  },
  computed: {
    store() {
      return this.$store.state;
    }
  },
  methods: {
    open(params) {
      this.item = {};
      this.promos = [];
      this.opened = true;
      if (params && params.id) this.get(params.id);
      this.getPromo();
    },
    close() {
      this.opened = false;
      this.$router.push({query: {}});
    },
    get(id) {
      this.loading = true;
      this.store.server.request(`mailingListItem/get/${id}`, {}, (data) => {
        this.loading = false;
        this.item = data.response;
      }, (data) => {
        this.$root.notify(data.error, 'error');
        this.loading = false;
      });
    },
    create() {
      this.loading_create = true;
      const method = this.item.id ? `update/${this.item.id}` : 'create';
      this.store.server.request(`mailingListItem/${method}`, this.item, (data) => {
        this.loading_create = false;
        this.$emit('on-create');
        if (method === 'create') {
          this.$router.push('/mailingLists/' + data.response.id);
        }
        this.close();
      })
    },
    getPromo() {
      this.loading_promos = true;
      this.store.server.request('promonew/get', {}, (data) => {
        this.loading_promos = false;
        this.promos = data.response;
      }, (data) => {
        this.$root.notify(data.error, 'error');
        this.loading_promos = false;
      })
    },
    setImage() {
      let file = this.item.imageFile;
      const formData = new FormData()
      formData.append('file', file, file.name)
      this.axios.post('https://cleanbros.ru/upload', formData)
          .then(data => {
            this.item.imageFile = null;
            this.item.img = data.data.response;
            this.item = this.clone(this.item);
          })
          .catch(e => {
            this.$root.notify(e, 'error')
          })
    },
    clickOnFileinput() {
      this.$refs.file_input.$refs.input.click();
    }
  },
}
</script>

<style scoped lang="scss">
.image-input {
  width: 185px;
  height: 185px;
  border-radius: 4px;
  border: 1px solid #dedede;
  cursor: pointer;
  background-size: cover;
}
</style>